import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirect, useNavigate, useOutletContext } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { NameForm } from "../../../AdminEventView/EventBasicInfo/NameForm";
import { LocationForm } from "../../../AdminEventView/EventBasicInfo/LocationForm";
import { BottomButtons } from "../../../AdminEventView/EventBasicInfo/BottomButtons";
import { FormPrompt } from "../../../FormPrompt/FormPrompt";
import LoadingOverlay from "../../../LoadingOverlay";
import {
  createOrganization,
  updateOrganization,
  organizationsSliceSelector,
} from "../../../../redux/features/organizationsSlice";
import ConfirmationDialog from "../../../ConfirmationDialog";

const validationSchema = yup.object({
  name: yup.string(),
  placeName: yup.string().required("Place name is required"),
  addressLine1: yup.string().trim().required("Address Line 1 is required"),
  addressLine2: yup.string().trim(),
  city: yup.string().trim().required("City is required"),
  state: yup.string().trim().required("State is required"),
  zipcode: yup
    .mixed()
    .test("required", "ZIP code is required", (value) => {
      return !!value;
    })
    .test("valid", "ZIP code must be valid", (value) => {
      return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    }),
});

const buildValuesPayload = (values) => {
  const addressFields = [
    "placeName",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zipcode",
  ];

  let newValues = { ...values };

  const address = addressFields.reduce((acc, addressField) => {
    const value = newValues[addressField];
    delete newValues[addressField];
    return {
      ...acc,
      [addressField]: value || "",
    };
  }, {});

  return { ...newValues, address };
};

const welcomeText = (creatingNew) => {
  if (!creatingNew) {
    return null;
  }
  return (
    <Box marginTop={"20px"} marginBottom={"20px"}>
      <Typography variant="h4">Welcome to Comedy Ticketing</Typography>
      <Typography variant="b1" marginTop={"20px"}>
        To begin, tell us about your organization.
      </Typography>
    </Box>
  );
};

const OrganizationBasicInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ignoreDirty, setIgnoreDirty] = useState(false);

  const { organization, creatingNew } = useOutletContext();
  const organizationsState = useSelector((state) =>
    organizationsSliceSelector(state),
  );
  const { creatingNewOrganization, currentOrganization } = organizationsState;

  if (!organization) {
    return null;
  }
  const {
    id: organizationId,
    name,
    address,
    loading,
    userIsOwner,
    squareIntegrationSuccess,
    squareLocations,
  } = organization;
  const { placeName, addressLine1, addressLine2, city, state, zipcode } =
    address || {};
  const initialValues = {
    name,
    placeName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipcode,
  };

  const handleSaveChanges = async (values) => {
    await dispatch(updateOrganization({ organizationId, values }));
  };
  const handleCreateNew = async (values) => {
    await dispatch(createOrganization({ values }));
  };

  return (
    <Box
      sx={{
        paddingTop: "30px",
        paddingBottom: "90px",
        position: "relative",
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, submitProps) => {
          if (creatingNew) {
            await setIgnoreDirty(true);
            await handleCreateNew(buildValuesPayload(values));
            await submitProps.resetForm({ values });
            navigate("/");
          } else {
            await handleSaveChanges(buildValuesPayload(values));
            submitProps.resetForm({ values });
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          handleReset,
          setFieldTouched,
        }) => (
          <Form noValidate autoComplete="off">
            <FormPrompt hasUnsavedChanges={!ignoreDirty && dirty} />
            {welcomeText(creatingNew && !currentOrganization)}
            <NameForm
              disabled={!userIsOwner && !creatingNew}
              formikProps={{
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
              }}
            />
            <LocationForm
              disabled={!userIsOwner && !creatingNew}
              formikProps={{
                values,
                handleChange,
                setFieldValue,
                handleBlur,
                touched,
                errors,
              }}
            />

            <BottomButtons
              show={creatingNew || (userIsOwner && dirty)}
              handleDiscard={handleReset}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default OrganizationBasicInfo;
