import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { Box, Container, Typography } from "@mui/material";
import {
  fetchOrders,
  organizationsSliceSelector,
} from "../../../../redux/features/organizationsSlice";
import { useDispatch, useSelector } from "react-redux";
import OrdersList from "../../../OrdersList";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { sendOrderConfirmationEmail } from "../../../../redux/features/adminEventsSlice";
import ResendOrderEmailConfirmationDialog from "./ResendOrderEmailConfirmationDialog/ResendOrderEmailConfirmationDialog";

const EventDashboard = ({}) => {
  const dispatch = useDispatch();
  const organizationsState = useSelector((state) =>
    organizationsSliceSelector(state),
  );
  const [confirmResendEmailOrderId, setConfirmResendEmailOrderId] =
    useState(null);
  const { currentOrganization: organization } = organizationsState;
  const { id: organizationId, orders } = organization || {};

  useEffect(() => {
    if (!orders && organizationId) {
      dispatch(fetchOrders({ organizationId }));
    }
  }, [organizationId, orders]);

  if (!orders) {
    return;
  }

  return (
    <Container>
      <ResendOrderEmailConfirmationDialog
        open={Boolean(confirmResendEmailOrderId)}
        handleCancel={() => setConfirmResendEmailOrderId(null)}
        handleConfirm={async ({ sendAttendees }) => {
          dispatch(
            sendOrderConfirmationEmail({
              orderId: confirmResendEmailOrderId,
              organizationId,
              sendAttendees,
            }),
          );
          setConfirmResendEmailOrderId(null);
        }}
      />
      <Typography variant="h2" paddingTop={"20px"}>
        Orders
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gap="20px">
        <OrdersList
          orders={orders}
          handleResendEmailClick={(orderId) => {
            setConfirmResendEmailOrderId(orderId);
          }}
        />
      </Box>
    </Container>
  );
};

export default EventDashboard;
